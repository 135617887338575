import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Forbidden from './pages/Forbidden';
import {
  useQuery,
  gql
} from "@apollo/client";
import ApolloProvider from "./apollo.services"

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Loading from './pages/Loading';
import Login from './pages/Login';
const VIEWER_INFO = gql`
  query viewerInfo {
    viewerInfo {
      id
      email
      role {
        id
        name
      }
    }
  }
`
const App = (props: any) => {
  const { data, loading, error } = useQuery(VIEWER_INFO)
  console.log("ACACA", data, loading, props)
  if (loading) {
    return <Loading text={""}/>
  }
  if (!data) {
    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/home" render={() => <Redirect to="/login" />} />
            <Route path="/login" component={Login} exact={true} />
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route exact path="/forbidden" render={() => <Redirect to="/login" />} />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    )
  } else {
    const {
      viewerInfo: {
        role: { name },
      },
    } = data
    if (["superadmin", "approvedAdmins"].includes(name)) {
      return (
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>
              <Route path="/home" component={Home} exact={true} />
              <Route path="/forbidden" component={Forbidden} exact={true} />
              <Route path="/login" render={() => <Redirect to="/home" />} />
              <Route exact path="/" render={() => <Redirect to="/home" />} />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      )
    }  else {
      return <Forbidden />
    }
  }

}
const RootApp: React.FC = () => {

  return (
    <ApolloProvider>
      <App />
    </ApolloProvider>
  )
};

export default RootApp;

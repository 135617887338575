import { useMutation } from "@apollo/react-hooks";
import { IonButton, IonCard, IonCardContent, IonContent, IonInput, IonItem, IonLabel, IonToast } from '@ionic/react';
import gql from "graphql-tag";
import React, { useState } from "react";
import { APOLLO_CONFIG } from "../../apollo.services";
import Logo from "../../assets/img/logo.png";

const styles = {
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
  },
  avatar: {
    // margin: theme.spacing(1),
    width: "30vw",
    maxWidth: 300,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
  },
  card: {
    // padding: theme.spacing(3),
  },
}

const VIEWER_INFO_QUERY = gql`
  query viewerInfo {
    viewerInfo {
      id
    }
  }
`

const useSignin = () => {
  const [signIn] = useMutation(
    gql`
      mutation signIn($email: String!, $password: String!) {
        signinUser(email: $email, password: $password) {
          token
          user {
            id
            email
            role {
              id
              name
            }
          }
        }
      }
    `,
    {
      update: (
        cache,
        {
          data: {
            signinUser: { token, user },
          },
        },
      ) => {
        cache.writeQuery({
          query: VIEWER_INFO_QUERY,
          data: { viewerInfo: user },
        })
        APOLLO_CONFIG.setToken(token)
      },
    },
  )
  return signIn
}
const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [toastMessage, setToastMessage] = useState("")
  const [showToast, setShowToast] = useState(false)
  const [loading, setLoading] = useState(false)
  const onChange = (setter: any) => (e: any) => setter(e.target.value)

  const signIn = useSignin()
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (email !== "" && password !== "" && !loading) {
      setLoading(true)
      try {
        const data = await signIn({ variables: { email, password } })
      } catch (e) {
        setLoading(false)
        setToastMessage("Credenciales incorrectas")
        setShowToast(true)
      }
    }
  }

  return (
    <>
      <IonContent color="primary">
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={2000}
        />
        <div style={{ ...styles.paper, flexDirection: "column" }}>
          <img src={Logo} style={styles.avatar} alt="LAPP" />
          <IonCard style={styles.card}>
            <IonCardContent>

              <form style={styles.form} onSubmit={handleSubmit}>
                <IonItem>
                  <IonLabel position="stacked">Email Address</IonLabel>
                  <IonInput
                    required
                    id="email"
                    name="email"
                    onIonChange={onChange(setEmail)}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonLabel position="stacked">Password</IonLabel>
                  <IonInput
                    required
                    name="password"
                    type="password"
                    id="password"
                    onIonChange={onChange(setPassword)}
                  ></IonInput>
                </IonItem>
                <IonButton color="primary"
                  type="submit"
                  style={styles.submit}
                  expand="block"
                  disabled={loading}
                >
                  Sign In
              </IonButton>
              </form>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </>
  )
}

export default Login

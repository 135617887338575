import React from "react"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
export const APOLLO_CONFIG = (() => {
  let token: any = null
  return {
    setToken: (newToken: any) => {
      token = newToken
    },
    clearToken: () => {
      token = null
    },
    getToken: () => token,
  }
})()

const httpLink = new HttpLink({
  // uri: `http://localhost:4000`,
  uri: `${process.env.REACT_APP_API_URL || "https://api.lapp.advancedlapp.com"}`,
})
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const authorizationHeader = APOLLO_CONFIG.getToken()
  if (authorizationHeader)
    operation.setContext({
      headers: {
        authorization: `Bearer ${authorizationHeader}`,
      },
    })
  return forward(operation)
})

const client = new ApolloClient({
  link: ApolloLink.from([middlewareAuthLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

const Provider = ({ children }: any) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)
export default Provider

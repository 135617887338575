import React from "react"
import { useApolloClient } from "@apollo/react-hooks"

import Helmet from "react-helmet"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Logo from "../../assets/img/logo.png"
import { APOLLO_CONFIG } from "../../apollo.services"
import { IonButton, IonCard, IonContent, IonText } from "@ionic/react"

const style = {
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
  },
  avatar: {
    // margin: theme.spacing(1),
    width: "30vw",
    maxWidth: 300,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
  },
  card: {
    // padding: theme.spacing(3),
  },
}
const Forbidden = () => {

  const client = useApolloClient()
  const logout = async () => {
    APOLLO_CONFIG.clearToken()
    await client.resetStore()
  }
  return (
    <>
      <IonContent color="primary">
        <div style={{...style.paper, flexDirection: 'column'}}>
          <img src={Logo} style={style.avatar} alt="LAPP" />
          <IonCard style={style.card}>
            <IonText>
              Ups, no tienes acceso a esta sección
            </IonText>
            <IonButton
              type="submit"
              color="secondary"
              style={style.submit}
              onClick={logout}
            >
              Logout
            </IonButton>
          </IonCard>
        </div>
      </IonContent>
      <div></div>
    </>
  )
}

export default Forbidden

import React from "react"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { IonButton, IonCard, IonCol, IonContent, IonGrid, IonRow, IonText } from "@ionic/react"
import GiantNumber from '../GiantNumber';
import Loading from "../../pages/Loading";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"
const style = {
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
  }
}
const Activity: React.FC<{ teacher: any }> = ({ teacher = null }: any) => {
  const { data: dataTeacher, loading: loadingDataTeacher, error } = useQuery(gql`
    query($id: ID!) {
      teacherInformation(where: { id: $id }) {
        timeByMonth
        approvedPercentageByLevel
        byMonth
        byLevelAndMonth
      }
    }
  `, {
    variables: {
      id: teacher ? teacher.id : null
    }
  })
  if (!teacher) {
    return <p>Seleccione un profesor</p>
  }
  if (loadingDataTeacher) {
    return (
      <Loading
        text={
          "Cargando informacion del usuario"
        }
      />
    )
  }
  if (error) {
    return <p>Ocurrio un error</p>
  }
  const byLevelAndMonth = Object.keys(dataTeacher.teacherInformation.byMonth).reduce((acc: any, val: any) => {
    acc.push({
      "name": val,
      "Basico": dataTeacher.teacherInformation.byLevelAndMonth['1'][val],
      "Avanzado": dataTeacher.teacherInformation.byLevelAndMonth['2'][val]
    })
    return acc
  }, [])
  const timesByMonth = Object.keys(dataTeacher.teacherInformation.byMonth).reduce((acc: any, val: any) => {
    acc.push({
      "name": val,
      "Tiempo": dataTeacher.teacherInformation.timeByMonth[val] ? dataTeacher.teacherInformation.timeByMonth[val].total : 0,
    })
    return acc
  }, [])
  console.log(dataTeacher, loadingDataTeacher)
  return (
    <>
      <IonContent>
        <div style={{ width: '90%', margin: 'auto' }}>
          <h2>Usuario: {teacher.name} {teacher.lastname}</h2>
          <IonGrid>
            <IonRow>
              <IonCol size={'6'}>
                <GiantNumber number={dataTeacher.teacherInformation.approvedPercentageByLevel['1'].total} title={"Evaluaciones basicas totales"} />
              </IonCol>
              <IonCol size={'6'}>
                <GiantNumber number={dataTeacher.teacherInformation.approvedPercentageByLevel['1'].percentage.toFixed(2)} title={"Porcetage de evaluciones aprobadas basicas"} />
              </IonCol>
              <IonCol size={'6'}>
                <GiantNumber number={dataTeacher.teacherInformation.approvedPercentageByLevel['2'].total} title={"Evaluaciones avanzadas totales"} />
              </IonCol>
              <IonCol size={'6'}>
                <GiantNumber number={dataTeacher.teacherInformation.approvedPercentageByLevel['2']?.percentage?.toFixed(2)} title={"Porcetage de evaluciones aprobadas avanzadas"} />
              </IonCol>
              <IonCol size={'12'}>
                <LineChart
                  width={800}
                  height={300}
                  data={byLevelAndMonth}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Basico" stroke="#8884d8" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="Avanzado" stroke="#82ca9d" />
                </LineChart>
                <div className="title">Evaluaciones mensuales por nivel</div>
              </IonCol>
              <IonCol size={'12'}>
                <LineChart
                  width={800}
                  height={300}
                  data={timesByMonth}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Tiempo" stroke="#8884d8" activeDot={{ r: 8 }} />
                </LineChart>
                <div className="title">Tiempo de evaluciones</div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </>
  )
}

export default Activity

import React from "react"
import Helmet from "react-helmet"


import Logo from "../../assets/img/logo.png"
import { IonContent, IonSpinner, IonText } from "@ionic/react"
const styles = {
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
  },
  avatar: {
    width: "30vw",
    maxWidth: 300,
  },
  text: {
    color: "#fff",
    marginBottom: 5,
    whiteSpace: "pre-line",
  }
}

const Loading: React.FC<{text: string}> = ({ text }: any) => {
  return (
    <IonContent color="primary">
      <div style={{...styles.paper, flexDirection: "column"}}>
        <img src={Logo} style={styles.avatar} alt="LAPP" />
        {text && (
          <IonText
            style={styles.text}
          >
            {text}
          </IonText>
        )}
        <IonSpinner color="secondary" />
      </div>
    </IonContent>
  )
}

export default Loading

import React, { useState, useEffect } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonSegment,
	IonSegmentButton,
	IonLabel,
	IonIcon,
	IonItem,
	IonButton,
	IonSearchbar,
	IonAlert,
	IonToast,
	IonBadge,
} from "@ionic/react";
import Activity from "../../components/Activity";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Loading from "../Loading";
import GiantNumber from "../../components/GiantNumber";
import {
	closeCircleOutline,
	eye,
	checkmarkCircleOutline,
} from "ionicons/icons";
import DataTable, { createTheme } from "react-data-table-component";
const csvDownload = require("json-to-csv-export");

const Home: React.FC = () => {
	const [segment, setSegment] = useState("home");
	const [activeSegment, setActiveSegment] = useState("active");
	const [searchText, setSearchText] = useState("");
	const [showAlertInactive, setShowAlertInactive] = useState(false);
	const [showAlertActive, setShowAlertActive] = useState(false);
	const [showPayAlert, setShowPayAlert] = useState(false);
	const [teacher, setTeacher]: any = useState(null);
	const [teacherToPay, setTeacherToPay]: any = useState(null);
	const [toastMessage, setToastMessage] = useState("");
	const [showToast, setShowToast] = useState(false);

	const [updateStatus] = useMutation(gql`
		mutation UpdateStatus($statusId: ID!, $userId: ID!) {
			updateStatus(statusId: $statusId, userId: $userId) {
				id
			}
		}
	`);
	const [payTeacher] = useMutation(gql`
		mutation PayWallet($walletId: ID!, $amount: Float!) {
			withdrawn(walletId: $walletId, amount: $amount) {
				id
			}
		}
	`);
	useEffect(() => {
		if (teacher) {
			setSegment("activity");
		}
	}, [teacher]);
	const { data: dataTeachers, loading: loadingDataTeachers } = useQuery(gql`
		query {
			getTeachers(where: { roleId: 3 }) {
				id
				email
				lastname
				name
				status {
					id
				}
				role {
					id
					name
				}
			}
		}
	`);
	const { data: dataWallets, loading: loadingWallets } = useQuery(gql`
		query {
			wallets {
				id
				balance
				onHold
				total
				payed
				teacherId
			}
		}
	`);
	const { data: dataStars, loading: loadingStars } = useQuery(gql`
		query {
			teachersStars
		}
	`);
	const { data: dataStats, loading: loadingDataStats } = useQuery(gql`
		query {
			teachersStats
		}
	`);
	if (
		loadingDataTeachers ||
		loadingDataStats ||
		loadingWallets ||
		loadingStars
	) {
		return (
			<Loading
				text={
					"Cargando lista de usuarios\nLa sesión es temporal, al finalizar solo cierre la pestaña"
				}
			/>
		);
	}
	const wallets = dataWallets.wallets.reduce((acc: any, val: any) => {
		acc[val.teacherId] = val;
		return acc;
	}, {});
	const rankProfit = Object.keys(wallets)
		.sort((a: any, b: any) => {
			if (wallets[a].total < wallets[b].total) {
				return -1;
			}
			if (wallets[a].total > wallets[b].total) {
				return 1;
			}
			return 0;
		})
		.map((id) => {
			let selected = {};
			dataTeachers.getTeachers.forEach((user: any) => {
				if (user.id == id) {
					selected = { id, ...user };
				}
			});
			return selected;
		})
		.reverse();
	const rank = Object.keys(dataStats.teachersStats)
		.reduce((acc: any, val: any) => {
			acc.push({
				id: val,
				count:
					dataStats.teachersStats[val]["1"] + dataStats.teachersStats[val]["2"],
			});
			return acc;
		}, [])
		.sort((a: any, b: any) => {
			if (a.count < b.count) {
				return -1;
			}
			if (a.count > b.count) {
				return 1;
			}
			return 0;
		})
		.reverse()
		.map((stat: any) => {
			let selected = {};
			dataTeachers.getTeachers.forEach((user: any) => {
				if (user.id == stat.id) {
					selected = { ...stat, ...user };
				}
			});
			return selected;
		});
	const changeStatus = async (status: number) => {
		try {
			const data = await updateStatus({
				variables: {
					statusId: status,
					userId: teacher.id,
				},
				refetchQueries: [
					{
						query: gql`
							query {
								getTeachers(where: { roleId: 3 }) {
									id
									email
									lastname
									name
									status {
										id
									}
									role {
										id
										name
									}
								}
							}
						`,
					},
				],
			});
			setToastMessage("Actualizado correctamente");
			setShowToast(true);
			setTeacher(null);
		} catch (err) {
			setToastMessage("Ocurrio un error, intenta de nuevo");
			setShowToast(true);
			setTeacher(null);
		}
	};
	const pay = async (amount: any, walletId: any) => {
		try {
			const data = await payTeacher({
				variables: {
					walletId,
					amount: parseFloat(amount),
				},
				refetchQueries: [
					{
						query: gql`
							query {
								wallets {
									id
									balance
									onHold
									total
									payed
									teacherId
								}
							}
						`,
					},
				],
			});
			setToastMessage("Pagado correctamente");
			setShowToast(true);
			setTeacher(null);
		} catch (err) {
			setToastMessage("Ocurrio un error, intenta de nuevo");
			setShowToast(true);
			setTeacher(null);
		}
	};
	const getInformation = () => {};

	const activeTeachers = dataTeachers.getTeachers.reduce(
		(acc: any, val: any) => {
			if (val.email.includes("trainee")) return acc;
			val.status.id == 1 ? acc.active++ : acc.inactive++;
			return acc;
		},
		{ active: 0, inactive: 0 }
	);
	const columns: any = [
		{
			name: "Posicion",
			width: 40,
			maxWidth: 50,
			selector: (row: any, index: number) => {
				return <p style={{ textAlign: "center" }}>{index + 1}</p>;
			},
			sortable: true,
		},
		{
			name: "Nombre",
			selector: (row: any) => {
				return `${row.name} ${row.lastname}`;
			},
			sortable: true,
		},
		{
			name: "Email",
			selector: "email",
			sortable: true,
		},
		{
			name: "Estatus",
			selector: (row: any) => {
				return row.status.id == 1 ? "Activo" : "Inactivo";
			},
			sortable: true,
		},
		{
			name: "Evaluaciones basicas",
			selector: (row: any) => {
				return dataStats?.teachersStats[row.id]
					? dataStats?.teachersStats[row.id]["1"]
					: 0;
			},
			sortable: true,
		},
		{
			name: "Evaluaciones avanzadas",
			selector: (row: any) => {
				return dataStats?.teachersStats[row.id]
					? dataStats?.teachersStats[row.id]["2"]
					: 0;
			},
			sortable: true,
		},
		{
			name: "Promedio de estrellas (0 - 5)",
			selector: (row: any) => {
				return dataStars.teachersStars[row.id]
					? (dataStars.teachersStars[row.id].average / 2).toFixed(1)
					: 0;
			},
			sortable: true,
		},
		{
			name: "Dinero total",
			selector: (row: any) => {
				return `$ ${wallets[row.id] ? wallets[row.id].total : 0}`;
			},
			sortable: true,
		},
		{
			name: "Dinero acumulado",
			selector: (row: any) => {
				return `$ ${
					wallets[row.id] ? wallets[row.id].balance + wallets[row.id].onHold : 0
				}`;
			},
			sortable: true,
		},
		{
			name: "Dinero retenido",
			selector: (row: any) => {
				return `$ ${wallets[row.id] ? wallets[row.id].onHold : 0}`;
			},
			sortable: true,
		},
		{
			name: "Dinero por pagar",
			selector: (row: any) => {
				return `$ ${wallets[row.id] ? wallets[row.id].balance : 0}`;
			},
			sortable: true,
		},
		{
			name: "Dinero pagado",
			selector: (row: any) => {
				return `$ ${wallets[row.id] ? wallets[row.id].payed : 0}`;
			},
			sortable: true,
		},
		{
			name: "Ver",
			width: "110px",
			cell: (row: any) => {
				return (
					<div data-tag='allowRowEvents'>
						<IonButton
							onClick={() => {
								setTeacher(row);
								getInformation();
							}}
							fill='outline'
							color='secondary'
						>
							<IonIcon slot='start' icon={eye}></IonIcon>
							Ver
						</IonButton>
					</div>
				);
			},
		},
		{
			name: "Cambiar",
			width: "150px",
			cell: (row: any) => {
				return (
					<div data-tag='allowRowEvents'>
						{activeSegment == "active" && (
							<IonButton
								onClick={() => {
									setShowAlertInactive(true);
									setTeacher(row);
								}}
								fill='outline'
								color='danger'
							>
								<IonIcon slot='start' icon={closeCircleOutline}></IonIcon>
								Bloquear
							</IonButton>
						)}
						{activeSegment == "inactive" && (
							<IonButton
								onClick={() => {
									setShowAlertActive(true);
									setTeacher(row);
								}}
								fill='outline'
								color='success'
							>
								<IonIcon slot='start' icon={checkmarkCircleOutline}></IonIcon>
								Activar
							</IonButton>
						)}
					</div>
				);
			},
		},
		{
			name: "Pagar",
			width: "150px",
			cell: (row: any) => {
				return (
					<div data-tag='allowRowEvents'>
						<IonButton
							onClick={() => {
								setTeacherToPay(row);
								setShowPayAlert(true);
							}}
							fill='outline'
							color='success'
						>
							<IonIcon slot='start' icon={eye}></IonIcon>
							Pagar
						</IonButton>
					</div>
				);
			},
		},
	];
	const rankColumns: any = [
		{
			name: "Posicion",
			width: 50,
			maxWidth: 50,
			selector: (row: any, index: any) => {
				return index + 1;
			},
			sortable: true,
		},
		{
			name: "Nombre",
			selector: (row: any) => {
				return `${row.name} ${row.lastname}`;
			},
			sortable: true,
		},
		{
			name: "Email",
			selector: "email",
			sortable: true,
		},
		{
			name: "Evaluaciones totales",
			selector: "count",
			sortable: true,
		},
	];
	const rankProfitColumns: any = [
		{
			name: "Posicion",
			width: 50,
			maxWidth: 50,
			selector: (row: any, index: any) => {
				return index + 1;
			},
			sortable: true,
		},
		{
			name: "Nombre",
			selector: (row: any) => {
				return `${row.name} ${row.lastname}`;
			},
			sortable: true,
		},
		{
			name: "Email",
			selector: "email",
			sortable: true,
		},
		{
			name: "Dinero total",
			selector: (row: any) => `$ ${wallets[row.id].total}`,
			sortable: true,
		},
	];
	const dataToCsv = dataTeachers.getTeachers.map((doc: any, index: number) => {
		return {
			Posicion: index + 1,
			name: doc.name + " " + doc.lastname,
			email: doc.email,
			status: doc.status.id == 1 ? "Activo" : "Inactivo",
			evaluacionesBasicas: dataStats?.teachersStats[doc.id]
				? dataStats?.teachersStats[doc.id]["1"]
				: 0,
			evaluacionesAvanzadas: dataStats?.teachersStats[doc.id]
				? dataStats?.teachersStats[doc.id]["2"]
				: 0,
			promedioDeEstrellas: dataStars.teachersStars[doc.id]
				? (dataStars.teachersStars[doc.id].average / 2).toFixed(1)
				: 0,
			dineroTotal:`$ ${wallets[doc.id] ? wallets[doc.id].total : 0}`,
			dineroAcumulado: `$ ${wallets[doc.id] ? wallets[doc.id].balance + wallets[doc.id].onHold : 0}`,
			dineroRetenido: `$ ${wallets[doc.id] ? wallets[doc.id].onHold : 0}`,
			dineroPorPagar: `$ ${wallets[doc.id] ? wallets[doc.id].balance : 0}`,
			dineroRetirado: `$ ${wallets[doc.id] ? wallets[doc.id].payed : 0}`,
		};
	});
  const csvData = [
    [
      "Nombre",
      "Email",
      "Estatus",
      "Evaluaciones Basicas",
      "Evaluaciones Avanzadas",
      "Promedio de estrella",
      "Dinero total",
      "Dinero acumulado",
      "Dinero retirado",
      "Dinero por pagar",
      "Dinero retenido",
    ],
    ...dataToCsv.map((doc: any) => {
      return [
        doc.name,
        doc.email,
        doc.status,
        doc.evaluacionesBasicas,
        doc.evaluacionesAvanzadas,
        doc.promedioDeEstrellas,
		doc.dineroTotal,
        doc.dineroAcumulado,
        doc.dineroRetirado,
        doc.dineroPorPagar,
        doc.dineroRetenido,
      ];
    }),
  ];
	return (
		<IonPage>
			<IonToast
				isOpen={showToast}
				onDidDismiss={() => setShowToast(false)}
				message={toastMessage}
				duration={2000}
			/>
			<IonAlert
				isOpen={showAlertInactive}
				onDidDismiss={() => setShowAlertInactive(false)}
				header={`Estas seguro que quieres bloquear este profesor | ${teacher?.name} ${teacher?.lastname}?`}
				message={"Este profesor no podra recibir nuevas evaluaciones"}
				buttons={[
					{
						text: "Cancelar",
						handler: () => {},
					},
					{
						text: "Bloquear",
						handler: () => {
							changeStatus(8);
						},
					},
				]}
			/>
			<IonAlert
				isOpen={showAlertActive}
				onDidDismiss={() => setShowAlertActive(false)}
				header={`Estas seguro que quieres activar este profesor | ${teacher?.name} ${teacher?.lastname}?`}
				message={"Este profesor podra volver a recibir nuevas evaluaciones"}
				buttons={[
					{
						text: "Cancelar",
						handler: () => {},
					},
					{
						text: "Activar",
						handler: () => {
							changeStatus(1);
						},
					},
				]}
			/>
			<IonAlert
				isOpen={showPayAlert}
				onDidDismiss={() => setShowPayAlert(false)}
				header={`Pagar a profesor ${teacherToPay?.name} ${teacherToPay?.lastname}`}
				inputs={[
					{
						name: "amount",
						placeholder: "Coloca la cantidad a pagar",
						type: "number",
						attributes: {
							inputmode: "decimal",
						},
					},
				]}
				buttons={[
					{
						text: "Cancelar",
						role: "cancel",
						cssClass: "secondary",
						handler: () => {},
					},
					{
						text: "Pagar",
						handler: (data) => {
							console.log(teacherToPay);
							pay(data.amount, wallets[teacherToPay.id]?.id);
						},
					},
				]}
			/>
			<IonHeader>
				<IonToolbar color='primary'>
					<IonTitle>Dashboard Pagos</IonTitle>
				</IonToolbar>
				<IonSegment
					scrollable
					style={{ background: "white" }}
					value={segment}
					onIonChange={(e: any) => {
						if (!teacher && e.detail.value == "activity") {
							setToastMessage(
								"Selecciona un usuario en la tabla para ver su informacion"
							);
							setShowToast(true);
							setSegment("home");
							return;
						}
						setSegment(e.detail.value);
					}}
				>
					<IonSegmentButton value='home'>
						<IonLabel>Inicio</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton value='activity'>
						<IonLabel>Actividad</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton value='evaluations'>
						<IonLabel>Ranking evaluaciones</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton value='profit'>
						<IonLabel>Ranking profit</IonLabel>
					</IonSegmentButton>
				</IonSegment>
			</IonHeader>
			<IonContent fullscreen>
				<div style={{ width: "90%", minWidth: 400, margin: "auto" }}>
					{segment == "home" && (
						<>
							<div
								style={{
									margin: 30,
									display: "flex",
									justifyContent: "space-around",
								}}
							>
								<GiantNumber
									number={activeTeachers.active}
									title={"Profesores activos"}
								/>
								<GiantNumber
									number={activeTeachers.inactive}
									title={"Profesores inactivos"}
								/>
							</div>
							<IonSearchbar
								value={searchText}
								onIonChange={(e) => setSearchText(e.detail.value!)}
							></IonSearchbar>
							<IonButton
								onClick={() => {
									csvDownload.default(csvData);
								}}
								color='primary'
							>
								Exportar a excel
							</IonButton>
							<DataTable
								style={{ marginBottom: 30 }}
								title='Profesores'
								columns={columns}
								fixedHeader={true}
								data={dataTeachers.getTeachers.filter((val: any) => {
									if (val.email.includes("trainee")) return false;
									if (
										searchText &&
										!val.name.includes(searchText) &&
											!val.lastname.includes(searchText) &&
											!val.email.includes(searchText)
									) {
										return null;
									} else if (!searchText) {
										return true;
									} else {
										return false;
									}
								})}
							/>
						</>
					)}
					{segment == "evaluations" && (
						<>
							<DataTable
								title='Profesores'
								fixedHeader={true}
								columns={rankColumns}
								data={rank}
							/>
						</>
					)}
					{segment == "profit" && (
						<>
							<DataTable
								title='Profesores'
								fixedHeader={true}
								columns={rankProfitColumns}
								data={rankProfit}
							/>
						</>
					)}
				</div>
				{segment == "activity" && (
					<>
						<Activity teacher={teacher} />
					</>
				)}
			</IonContent>
		</IonPage>
	);
};

export default Home;

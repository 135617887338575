import React from "react"

const style = {
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  circle: {
    background: "#2ba5b9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    fontSize: "50px",
    padding: "45px",
    color: "white",
    height: 170,
    width: 170,
  },
  title: {
    fontSize: '24px',
  }
}
const Activity = ({ number, title }: any) => {

  return (
    <div style={{ ...style.box, flexDirection: 'column' }}>
      <div style={style.circle}>
        {number}
      </div>
      <div style={style.title}>
        {title}
      </div>
    </div>
  )
}

export default Activity
